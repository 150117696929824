import axiosInstance from "./axios";

export class AuthService {
  register(data: any) {
    return axiosInstance.post("/auth/signup", data);
  }

  login(data: any) {
    return axiosInstance.post("/auth/signin", data);
  }

  loginOpenID(data: any) {
    return axiosInstance.post("/auth/signinoidc", data);
  }
}
