import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { useNavigate } from "react-router-dom";
type Props = {
  additionalStyles: string;
};

export const LanguageButtons = (props: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { i18n } = useTranslation();
  //const navigate = useNavigate();
  const toggleSetIsActive = () => {
    setIsActive(!isActive);
  };
  /*
  const goToImpressum = () => {
    navigate("/impressum");
  };*/

  return (
    <div className={"buttons " + props.additionalStyles}>
      <button
        onClick={() => {
          toggleSetIsActive();
          i18n.changeLanguage("de");
        }}
        className={classNames("button is-white mx-1", {
          "has-text-weight-semibold": i18n.language === "de",
          "is-underlined": i18n.language === "de",
          "has-text-weight-light": i18n.language !== "de",
        })}
      >
        DE
      </button>
      <button
        onClick={() => {
          toggleSetIsActive();
          i18n.changeLanguage("en");
        }}
        className={classNames("button is-white mx-1", {
          "has-text-weight-semibold": i18n.language === "en",
          "is-underlined": i18n.language === "en",
          "has-text-weight-light": i18n.language !== "en",
        })}
      >
        EN
      </button>
      {/*
      <button
        onClick={() => {
          goToImpressum();
        }}
      >
        fff
      </button>*/}
    </div>
  );
};
