import { ChangeEvent } from "react";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  privacyPolicyChecked: boolean;
  onChangePrivacyPolicyCheckbox: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const PrivacyPolicyCheckbox = (props: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="columns">
        <div className="column">
          <label className="label has-text-weight-normal">
            <input
              className="checkbox mr-1"
              type="checkbox"
              name="checkboxPrivacy"
              checked={props.privacyPolicyChecked}
              onChange={props.onChangePrivacyPolicyCheckbox}
            />
            <Trans t={t} i18nKey="privacyPolicy">
              {/* DATENSCHUTZERKLÄRUNG LINK HIER EINFÜGEN STATT "https://login.keamod.de/" */}
              <a href="https://docs.keamod.de/#/datenschutz.md" target="_blank" rel="noreferrer">
                {" "}
              </a>
            </Trans>
          </label>
        </div>
      </div>
    </>
  );
};
