import { ReactNode, useEffect, useState } from "react";

import { UserInfo } from "types/UserInfoType";
import { SessionContext } from "./SessionContext";

interface Props {
  children?: ReactNode;
}

export const SessionContextProvider = (props: Props) => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [role, setRole] = useState<string | undefined>(undefined);

  useEffect(() => {
    updateSession();
    // @ts-ignore
  }, []); // TODO: remove dependency array?

  // @ts-ignore
  const redirectToStudentUI = () => {
    const url = window?._env_?.REACT_APP_STUDENT_UI_URL;
    const port = window?._env_?.REACT_APP_STUDENT_UI_PORT;
    if (!url || !port) return;
    window.location.href = `${url}:${port}/assignments`;
  };

  // @ts-ignore
  const redirectToAuthorUI = () => {
    const url = window?._env_?.REACT_APP_AUTHOR_UI_URL;
    const port = window?._env_?.REACT_APP_AUTHOR_UI_PORT;
    if (!url || !port) return;
    window.location.href = `${url}:${port}/assignments`;
  };

  useEffect(() => {
    const redirect = () => {
      if (role === "student") redirectToStudentUI();
      if (role === "author") redirectToAuthorUI();
    };

    redirect();
  }, [role]);

  const getCookie = (name: string) => {
    return document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ``;
  };

  const getRoleCookie = (cookie: UserInfo) => {
    return cookie.roles[0];
  };

  const getNameCookie = (cookie: UserInfo) => {
    return cookie.user;
  };

  const updateSession = () => {
    console.log("updating session...");
    const uriCookie = decodeURIComponent(getCookie(`user.info`));
    console.log("uriCookie: ", uriCookie);
    if (!uriCookie) return;
    console.log("further");
    const userCookie = JSON.parse(uriCookie);

    const role = getRoleCookie(userCookie);
    if (role) {
      setRole(role);
    }

    const userName = getNameCookie(userCookie);
    if (name) {
      setName(userName);
    }
  };

  return (
    <SessionContext.Provider
      value={{
        name,
        role,
        updateSession,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};
