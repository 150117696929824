import { CSSProperties, ReactNode, useEffect, useState } from "react";

import { NotificationContext } from "./NotificationContext";
import { NotificationMessage } from "types/NotificationMessageType";
import classNames from "classnames";

interface Props {
  children?: ReactNode;
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    position: "fixed",
    left: "1rem",
    right: "1rem",
    margin: "auto",
    bottom: 30,
    maxWidth: 600,
  },
};

export const NotificationContextProvider = (props: Props) => {
  const [notification, setNotification] = useState<NotificationMessage | undefined>(undefined);

  useEffect(() => {
    if (notification) {
      setTimeout(dismissNotification, notification.displayTime);
    }
  }, [notification, setNotification]);

  const dismissNotification = () => {
    setNotification(undefined);
  };

  const getButtonMarkup = () => {
    return (
      <div
        className={classNames("column", {
          "is-two-fifths": notification?.button !== null,
        })}
      >
        <button
          onClick={notification?.button?.clb}
          className={classNames("button is-pulled-right", notification?.button?.className)}
        >
          {notification?.button?.label}
        </button>
      </div>
    );
  };

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification,
      }}
    >
      {props.children}
      <div style={styles.container}>
        {notification && (
          <div className={"notification " + notification.type}>
            <button onClick={dismissNotification} className="delete" />
            <div className="container">
              <div className="columns is-vcentered is-clipped">
                <div
                  className={classNames("column", {
                    "is-three-fifths": notification.hasOwnProperty("button"),
                  })}
                >
                  {notification.message}
                </div>
                {notification.button && getButtonMarkup()}
              </div>
            </div>
          </div>
        )}
      </div>
    </NotificationContext.Provider>
  );
};
