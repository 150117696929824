import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  loginPassword: string;
  onChangeLoginPassword: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const LoginPasswordInputFieldComponent = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="field">
      <label className="label has-text-centered is-unselectable">{t("password")}</label>
      <input className="input" type="password" value={props.loginPassword} onChange={props.onChangeLoginPassword} />
    </div>
  );
};
