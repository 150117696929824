import { AuthenticationController } from "./AuthenticationController";
import { UserRoleType } from "../../types/UserRoleType";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ImpressumPage } from "./components/Impressum";
type Props = {};

const CHANGE_USER_ROLE_TYPE_ENABLED = true;

export const AuthenticationPage = (_props: Props) => {
  const [userRoleType, setUserRoleType] = useState<UserRoleType>(UserRoleType.Student);

  return (
    <Routes>
      <Route path={`/impressum`} element={<ImpressumPage />} />
      <Route
        path={`/`}
        element={
          <AuthenticationController
            changeRoleTypeEnabled={CHANGE_USER_ROLE_TYPE_ENABLED}
            userRoleType={userRoleType}
            setUserRoleType={setUserRoleType}
          />
        }
      />
    </Routes>
  );
};
