import axios from "axios";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL:
    window?._env_?.REACT_APP_REQUESTMANAGEMENT_URL + ":" + window?._env_?.REACT_APP_REQUESTMANAGEMENT_PORT + "/api/v1",
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     // TODO: Was ist mit 403= Auch eni SignOut vornehmen und Cookie löschen..
//     if (error.response.data.path !== "/api/v1/auth/signin" && error.response.status === 401) {
//       const url = window?._env_?.REACT_APP_LANDINGPAGE_URL;
//       if (!url) return error;
//       // window.location.href = url;
//     }
//     return error;
//   }
// );
axiosInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    // Überprüfen, ob error.response, error.response.data und error.response.status existieren
    if (
      error.response &&
      error.response.data &&
      error.response.status &&
      error.response.data.path !== "/api/v1/auth/signin" &&
      error.response.status === 401
    ) {
      const url = window?._env_?.REACT_APP_LANDINGPAGE_URL;
      if (!url) return Promise.reject(error);
      // window.location.href = url;
    }
    return Promise.reject(error);
  },
);
export default axiosInstance;
