import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  loginName: string;
  onChangeLoginName: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const LoginNameInputFieldComponent = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="field">
      <label className="label has-text-centered is-unselectable">{t("name")}</label>
      <input className="input" value={props.loginName} onChange={props.onChangeLoginName} />
    </div>
  );
};
