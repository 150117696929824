import { APISignInBody, APISignInBodyOpenID, APISignInResponse } from "types/APISignInTypes";
import { APISignUpBody, APISignUpResponse } from "types/APISignUpTypes";
import { NotificationMessage, NotificationMessageType } from "types/NotificationMessageType";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { AuthService } from "communication/auth";
import { AuthenticationType } from "types/AuthenticationType";
import { LanguageButtons } from "./components/LanguageButtons";
import { LoginRegisterTabComponent } from "./components/LoginRegisterTabComponent";
import { LoginView } from "./LoginView";
import { RegisterView } from "./RegisterView";
import { UserRoleType } from "types/UserRoleType";
import { defineObjectProperty } from "common/Object";
import { useNotificationContext } from "context/notification/NotificationContext";
import { useSessionContext } from "context/session/SessionContext";
import { useTranslation } from "react-i18next";

type Props = {
  changeRoleTypeEnabled: boolean;
  userRoleType: UserRoleType;
  setUserRoleType: (value: UserRoleType) => void;
};

const getCookie = (name: string) => {
  return document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ``;
};

const getCustomNotificationMessage = (msg: string, type: NotificationMessageType): NotificationMessage => {
  return {
    displayTime: 6000000,
    message: msg,
    type: type,
  };
};

export const AuthenticationController = (props: Props) => {
  const { t } = useTranslation();
  const notificationContext = useNotificationContext();
  const sessionContext = useSessionContext();
  const auth = useAuth();
  const { signoutRedirect } = useAuth(); // <-- Extrahiere die Methode

  const [authenticationType, setAuthenticationType] = useState<AuthenticationType>(AuthenticationType.Login);
  const [loginName, setLoginName] = useState<string>("");
  const [loginPassword, setLoginPassword] = useState<string>("");

  const [secondaryPassword, setSecondaryPassword] = useState<string>("");
  const [emailAdress, setEmailAdress] = useState<string>("");
  const [secondaryEmailAdress, setSecondaryEmailAdress] = useState<string>("");
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState<boolean>(false);

  // Diese Funktion sollte aufgerufen werden, wenn der Logout-Button geklickt wird.
  const handleLogout = () => {
    signoutRedirect();
  };

  const performLogin = useCallback(
    (body: APISignInBody) => {
      const authService = new AuthService();
      authService.login(body).then(
        (res: any) => {
          if ("response" in res) {
            const response: APISignInResponse = res.response.data;

            if (response.success) {
              console.log("first success sign in");
              const uriCookie = decodeURIComponent(getCookie(`user.info`));
              console.log("first uriCookie: ", uriCookie);
              sessionContext.updateSession();
            } else {
              if (res.response.status === 401) {
                notificationContext.setNotification(
                  getCustomNotificationMessage(t("InvalidCredentialsNotification"), "is-danger"),
                );
              } else {
                notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
              }
            }
          } else {
            const response: APISignInResponse = res.data;

            if (response.success) {
              console.log("second success sign in");
              const uriCookie = decodeURIComponent(getCookie(`user.info`));
              console.log("2nd uriCookie: ", uriCookie);
              sessionContext.updateSession();
            } else {
              if (res.status === 401) {
                notificationContext.setNotification(
                  getCustomNotificationMessage(t("InvalidCredentialsNotification"), "is-danger"),
                );
              } else {
                notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
              }
            }
          }
        },
        (err) => {
          if (err.response.status === 401) {
            notificationContext.setNotification(
              getCustomNotificationMessage(t("InvalidCredentialsNotification"), "is-danger"),
            );
          } else notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
        },
      );
    },
    [notificationContext, sessionContext, t],
  );

  const performLoginOpenID = useCallback(
    (body: APISignInBodyOpenID) => {
      const authService = new AuthService();
      authService.loginOpenID(body).then(
        (res: any) => {
          if ("response" in res) {
            const response = res.response.data;
            console.log(response);

            if (response.success) {
              console.log("first success sign in");
              const uriCookie = decodeURIComponent(getCookie(`user.info`));
              console.log("first uriCookie: ", uriCookie);
              sessionContext.updateSession();
            } else {
              notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
            }
          } else {
            const response: APISignInResponse = res.data;

            if (response.success) {
              console.log("second success sign in");
              const uriCookie = decodeURIComponent(getCookie(`user.info`));
              console.log("2nd uriCookie: ", uriCookie);
              sessionContext.updateSession();
            } else {
              notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
            }
          }
        },
        (err) => {
          if (err.response.status !== 401) {
            notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
          }
        },
      );
    },
    [notificationContext, sessionContext, t],
  );

  const isLoginDataValid = useCallback((): boolean => {
    if (loginName === "") {
      notificationContext.setNotification(getCustomNotificationMessage(t("EmptyNameNotification"), "is-info"));
      return false;
    }
    if (loginPassword === "") {
      notificationContext.setNotification(getCustomNotificationMessage(t("EmptyPWNotification"), "is-info"));
      return false;
    }
    return true;
  }, [loginName, loginPassword, notificationContext, t]);

  const login = useCallback(() => {
    if (!isLoginDataValid()) return;
    const requestBody = {
      token: loginName,
      password: loginPassword,
    };
    performLogin(requestBody);
  }, [loginName, loginPassword, performLogin, isLoginDataValid]);

  const loginOpenID = () => {
    if (auth.isAuthenticated) {
      const requestBodyOpenID = {
        accessToken: String(auth.user?.access_token),
        profile: auth.user?.profile,
      };

      performLoginOpenID(requestBodyOpenID);
    } else {
      auth.signinRedirect();
    }
  };
  useEffect(() => {
    // Überprüfe, ob die Referrer-URL 'student.beta.keamod.de' enthält
    if (document.referrer.includes(window?._env_?.REACT_APP_STUDENT_UI_URL)) {
      // Wenn ja, rufe die handleLogout Funktion auf
      handleLogout();
    }
  }, []); // Leere Abhängigkeits-Array bedeutet, dass dieser Effekt nur einmal ausgeführt wird, wenn die Komponente gemountet wird.
  const performSignup = useCallback(
    (body: APISignUpBody) => {
      const authService = new AuthService();
      authService.register(body).then(
        (res: any) => {
          if ("response" in res) {
            const response: APISignUpResponse = res.response.data;
            if (response.success) {
              login();
            } else {
              if (res.response.status === 400) {
                notificationContext.setNotification(
                  getCustomNotificationMessage(t("AlreadySignedUpNotification"), "is-danger"),
                );
              } else {
                notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
              }
            }
          } else {
            const response: APISignUpResponse = res.data;
            if (response.success) {
              login();
            } else {
              if (res.status === 400) {
                notificationContext.setNotification(
                  getCustomNotificationMessage(t("AlreadySignedUpNotification"), "is-danger"),
                );
              } else {
                notificationContext.setNotification(getCustomNotificationMessage(t("ErrorNotification"), "is-danger"));
              }
            }
          }
        },
        (err) => {
          if (err.response.status === 400) {
            notificationContext.setNotification(
              getCustomNotificationMessage(t("AlreadySignedUpNotification"), "is-danger"),
            );
          }
        },
      );
    },
    [login, notificationContext, t],
  );

  const isSignUpDataValid = useCallback((): boolean => {
    if (loginName === "") {
      notificationContext.setNotification(getCustomNotificationMessage(t("EmptyNameNotification"), "is-info"));
      return false;
    }
    if (loginPassword === "") {
      notificationContext.setNotification(getCustomNotificationMessage(t("EmptyPWNotification"), "is-info"));
      return false;
    }

    if (loginPassword !== secondaryPassword) {
      notificationContext.setNotification(getCustomNotificationMessage(t("PWsDoNotMatch"), "is-danger"));
      return false;
    }

    if (emailAdress !== secondaryEmailAdress) {
      notificationContext.setNotification(getCustomNotificationMessage(t("EMailsDoNotMatch"), "is-danger"));
      return false;
    }

    if (privacyPolicyChecked !== true) {
      notificationContext.setNotification(getCustomNotificationMessage(t("AcceptPrivacyPolicy"), "is-danger"));
      return false;
    }
    return true;
  }, [
    loginName,
    loginPassword,
    emailAdress,
    secondaryPassword,
    secondaryEmailAdress,
    privacyPolicyChecked,
    notificationContext,
    t,
  ]);

  const register = useCallback(() => {
    if (!isSignUpDataValid()) return;
    const roles = [];
    if (props.userRoleType === UserRoleType.Author) roles.push("author");
    if (props.userRoleType === UserRoleType.Student) roles.push("student");

    let data: APISignUpBody = {
      token: loginName,
      password: loginPassword,
      roles,
    };
    if (emailAdress !== "") defineObjectProperty(data, "email", emailAdress);

    performSignup(data);
  }, [loginName, loginPassword, emailAdress, performSignup, props.userRoleType, isSignUpDataValid]);

  const isEnterPressed = useCallback((event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      return true;
    }
    return false;
  }, []);

  const onKeyDownListener = useCallback(
    (event: KeyboardEvent) => {
      if (!isEnterPressed(event)) return;
      authenticationType === AuthenticationType.Login ? login() : void 0;
      authenticationType === AuthenticationType.Register ? register() : void 0;
    },
    [authenticationType, isEnterPressed, login, register],
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDownListener);
    return () => {
      document.removeEventListener("keydown", onKeyDownListener);
    };
  }, [authenticationType, onKeyDownListener]);

  return (
    <div className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="box">
              <div className="columns">
                <div className="column is-full">
                  <LanguageButtons additionalStyles="is-right" />
                </div>
              </div>

              <div className="columns">
                <div className="column is-full is-unselectable mt-5 has-text-centered is-centered">
                  <figure className="image is-128x128 is-inline-block">
                    <img draggable="false" className="is-rounded" src="/assets/KEA-Mod_Logo_RGB_S.png" alt="KEA-Mod" />
                  </figure>
                </div>
              </div>

              <div className="columns mb-0 pb-0">
                <div className="column is-unselectable has-text-centered has-text-success mt-0 pt-0 is-size-4 has-text-weight-light">
                  {t("keamod")}
                </div>
              </div>

              <LoginRegisterTabComponent
                additionalStyles="is-centered"
                authenticationType={authenticationType}
                onChangeAuthenticationType={setAuthenticationType}
              />

              {authenticationType === AuthenticationType.Login && (
                <LoginView
                  loginName={loginName}
                  setLoginName={setLoginName}
                  loginPassword={loginPassword}
                  setLoginPassword={setLoginPassword}
                  login={login}
                  loginOpenID={loginOpenID}
                />
              )}
              {authenticationType === AuthenticationType.Register && (
                <RegisterView
                  loginName={loginName}
                  setLoginName={setLoginName}
                  loginPassword={loginPassword}
                  setLoginPassword={setLoginPassword}
                  secondaryPassword={secondaryPassword}
                  setSecondaryPassword={setSecondaryPassword}
                  register={register}
                  changeRoleTypeEnabled={props.changeRoleTypeEnabled}
                  userRoleType={props.userRoleType}
                  setUserRoleType={props.setUserRoleType}
                  emailAdress={emailAdress}
                  setEmailAdress={setEmailAdress}
                  secondaryEmailAdress={secondaryEmailAdress}
                  setSecondaryEmailAdress={setSecondaryEmailAdress}
                  privacyPolicyChecked={privacyPolicyChecked}
                  setPrivacyPolicyChecked={setPrivacyPolicyChecked}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
