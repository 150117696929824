import { AuthenticationType } from "types/AuthenticationType";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type Props = {
  authenticationType: AuthenticationType;
  onChangeAuthenticationType: (type: AuthenticationType) => void;
  additionalStyles: string;
};

export const LoginRegisterTabComponent = (props: Props) => {
  const { t } = useTranslation();
  return (
    <nav className={"breadcrumb " + props.additionalStyles}>
      <ul>
        <li
          className={classNames({
            "is-active": props.authenticationType === AuthenticationType.Login,
          })}
        >
          <a onClick={() => props.onChangeAuthenticationType(AuthenticationType.Login)}>{t("logIn")}</a>
        </li>
        <li
          className={classNames({
            "is-active": props.authenticationType === AuthenticationType.Register,
          })}
        >
          <a onClick={() => props.onChangeAuthenticationType(AuthenticationType.Register)}>{t("signUp")}</a>
        </li>
      </ul>
    </nav>
  );
};
