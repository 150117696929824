import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      openId: "Log in with KIT-account",
      keamod: "KEA-Mod platform",
      percentage: "Percentage",
      headerTitleTeacher: "Author UI",
      headerTitleStudent: "Student UI",
      assignment: "Assignment",
      assignments: "Assignments",
      newAssignment: "New assignment",
      assignmentList: "Assignments",
      exercise: "Exercise",
      exercises: "Exercises",
      newExercise: "New exercise",
      newScheme: "New scheme",
      exerciselist: "Exercises ",
      modellist: "Models ",
      schemelist: "Schemes ",
      task: "Task",
      search: "Search",
      deleteAll: "Delete all",
      title: "Title",
      description: "Description",
      status: "Status",
      edit: "Edit",
      chooseTask: "Choose task",
      chooseScheme: "Choose scheme",
      chooseModel: "Choose model",
      signIn: "Sign in",
      published: "Published",
      pending: "Pending",
      unpublish: "Unpublish",
      delete: "Delete",
      update: "Update",
      updated: "Updated",
      chooseAssignment: "Please click on an assignment",
      submitSuccessfull: "Successfully submitted!",
      send: "Send",
      enterTitle: "Enter title",
      artifacts: "Artifacts",
      create: "Create",
      created: "Created",
      add: "Add",
      name: "Name",
      type: "Type",
      modellinglang: "Modelling language",
      modellingTool: "Modelling tool",
      models: "Models",
      language: "Language",
      scenario: "Scenario",
      modelCreation: "Model creation",
      modelUnderstanding: "Model understanding",
      bpmn: "BPMN",
      epc: "EPC",
      petrinet: "Petri net",
      umlClass: "UML class diagram",
      umlSequence: "UML sequence diagram",
      sampleSolution: "Sample solution",
      newModel: "Create new sample solution",
      createModelButton: "Create",
      model: "Model",
      modelId: "Model ID",
      chooseFile: "Choose file",
      answer: "Answer",
      wronganswers: "Wrong answers",
      correctanswers: "Correct answers",
      scheme: "Assessment scheme",
      schemes: "Assessment schemes",
      visibility: "visibility",
      private: "private",
      internal: "internal",
      public: "public",
      question: "Question",
      signUp: "Sign up",
      logOut: "Logout",
      existing_model: "Existing model",
      startAssessment: "Start assessment",
      showAssessment: "Show assessment results",
      selectSubmission: "Select submission",
      refresh: "refresh",
      selectModellinglang: "Select modelling language",
      selectScheme: "Select assessment scheme",
      selectSampleSolution: "Select sample solution",
      selectVisibility: "Select visibility",
      Todo: "To do",
      submit: "Submit",
      MarkasReady: "Mark as ready",
      back: "back",
      Ready: "ready",
      submissions: "Submissions",
      addNewAssignment: "Add new assignment",
      open: "Open",
      submitted: "Submitted",
      enterAccessCode: "Please enter your access code",

      acesscodeCardHeader: "Add assignment",
      acesscodeCardInputLabel: "enter access code here",
      acesscodeCardBody:
        "Here you can add an assignment to your account. Please use the 6-digit access code given to you.",

      openSubmissionsCardHeader: "Open assignments",
      openSubmissionsCardBody:
        "Here are your current active assignments. Each assignment can contain multiple exercises. Click on an assignment to start editing.",
      openSubmissionsCardTableRowName: "Name",
      openSubmissionsCardTableRowTimeRemaining: "Time remaining",
      openSubmissionsCardTableRowStatus: "Status",
      openSubmissionsCardTableRowEmpty:
        "There are currently no assignments. Try adding a new assignment with an access code!",

      SubmittedSubmissionsCardHeader: "Submitted assignments",
      SubmittedSubmissionsCardBody:
        "Here you can find and review your already finished assignments. You will also be able to view results and feedback.",
      SubmittedSubmissionsCardTableRowName: "Name",
      SubmittedSubmissionsCardTableRowCreated: "Submitted at",
      SubmittedSubmissionsCardTableRowStatus: "Status",
      openSubmissionsCardTableRowNumberOfExercises: "Exercises",
      SubmittedSubmissionsCardTableRowEmpty:
        "There are currently no finished assignments. Try submitting an finished assignment!",

      RemainingTimeTagLabel: "{{count}} day",
      RemainingTimeTagLabel_plural: "{{count}} days",
      RemainingTimeTagLabelLessThan: "< {{count}} day",
      RemainingTimeTagLabelLessThan_plural: "< {{count}} days",
      RemainingTimeTagLabelExpired: "expired",

      AssignmentTableRowName: "Name",
      AssignmentTableRowLanguage: "Modeling language",
      AssignmentTableRowDescription: "Task",
      AssignmentEditButton: "Edit",
      AssignmentSubmitButton: "Submit",
      ShowFeedback: "Results",
      ShowNoFeedback: "No results",
      comment: "Comment",
      Score: "Score",
      category: "Category",
      valuation: "Valuation",
      content: "Content",
      PositionX: "Position X",
      PositionY: "Position Y",
      AssessmentId: "Assessment id",
      Assessment: "Grading",
      seeFeedback: "Here you can see your feedback.",
      integratedFeedback: "Integrated feedback",
      viewStatic: "View",
      ConfirmSubmit:
        "Really submit assignment? This step cannot be undone and you will no longer be able to edit your submissions!",
      exerciseType: "Exercise type",
      gradingServices: "Grading Services",
      gradingService: "Grading Service",
      partialScore: "Partial score",
      weight: "Weight",
      result: "Result",
      resulttype: "Result type",
      RELATIVE: "relative",
      ABSOLUTE: "absolute",
      requested: "Started",
      finished: "Finished",
      password: "Password",
      email: "E-mail address (optional)",
      confirmEmail: "Confirm e-mail address",
      confirmPassword: "Confirm Password",
      returnToLogin: "Return to login",
      ErrorNotification: "Unknown error occurred",
      EmptyNameNotification: "Please provide a name",
      EmptyPWNotification: "Please provide a password",
      EmptyUINotification: "Please choose a UI ",
      AlreadySignedUpNotification: "Name is already taken",
      PWsDoNotMatch: "Password do not match",
      EMailsDoNotMatch: "E-Mail addresses do not match",
      privacyPolicy: "I have read and accepted <0>the privacy policy</0>.",
      AcceptPrivacyPolicy: "Please accept the privacy policy!",
      BewarePW:
        "Please note that you can not reset your password in case you forget it! We highly recommend that you store your passwort safely.",
      RecommendPW:
        "We highly recommend that you store your password safely and offer you the optional possibility to send you an e-mail with your credentials. Your e-mail address will not be stored in the platform database.",
      ChooseUI: "Please choose your desired role in the platform",
      StudentUI: "Student",
      AuthorUI: "Author",
      SwitchRoleAuthor: "Switch to author mode",
      SwitchRoleStudent: "Switch to student mode",
      InvalidCredentialsNotification: "Invalid credentials",
      waitingforAssessment: "Waiting for an assessment",
      logIn: "Log in",
      role: "Role",
      BewareName:
        "If you want to avoid disclosing your identity, choose a name that does not allow for an (easy) identification.",
    },
  },
  de: {
    translation: {
      role: "Rolle",
      BewareName:
        "Hinweis: Wenn Sie eine direkte Identifikation Ihrer Person vermeiden möchten, achten Sie bitte darauf, eine unverfängliche Kennung zu wählen. ",
      waitingforAssessment: "Ausstehend",
      InvalidCredentialsNotification: "Falsche Zugangsdaten",
      SwitchRoleAuthor: "Zur Dozierendenansicht",
      SwitchRoleStudent: "Zur Studierendenansicht",
      StudentUI: "Student*in",
      AuthorUI: "Dozent*in",
      BewarePW:
        "Hinweis: Bitte beachten Sie, dass es nicht möglich ist, ein vergessenes Passwort zurückzusetzen! Wir empfehlen daher, dass Sie Ihr Passwort an einem sicheren Ort aufbewahren. ",
      RecommendPW:
        "Wir empfehlen daher, dass Sie Ihr Passwort an einem sicheren Ort aufbewahren und bieten die optionale Möglichkeit an, Ihnen eine E-Mail mit Ihren Zugangsdaten zuzusenden. Ihre E-Mail Adresse wird dabei nicht in der Plattform gespeichert.",
      ChooseUI: "Bitte wählen Sie die gewünschte Rolle in der Plattform",
      ErrorNotification: "Unbekannter Fehler aufgetreten",
      EmptyNameNotification: "Bitte geben Sie eine Kennung an",
      EmptyPWNotification: "Bitte geben Sie ein Passwort an",
      EmptyUINotification: "Bitte wählen Sie eine Rolle aus",
      AlreadySignedUpNotification: "Name ist bereits vergeben",
      PWsDoNotMatch: "Die Passwörter stimmen nicht überein",
      EMailsDoNotMatch: "Die E-Mail Adressen stimmen nicht überein",
      privacyPolicy: "Ich habe die <0>Datenschutzerklärung</0> zur Kenntnis genommen und akzeptiert.",
      AcceptPrivacyPolicy: "Bitte akzeptieren Sie die Datenschutzerklärung!",
      keamod: "KEA-Mod Plattform",
      requested: "Angelegt",
      finished: "Fertiggestellt",
      resulttype: "Ergebnistyp",
      RELATIVE: "relativ",
      ABSOLUTE: "absolut",
      weight: "Gewichtung",
      result: "Ergebnis",
      partialScore: "Teilpunkte",
      gradingService: "Bewertungsdienst",
      gradingServices: "Bewertungsdienste",
      headerTitleTeacher: "Author UI",
      headerTitleStudent: "Student UI",
      assignment: "Aufgabenreihe",
      assignments: "Aufgabenreihen",
      newAssignment: "Neue Aufgabenreihe",
      assignmentList: "Aufgabenreihen",
      exercise: "Aufgabe",
      exercises: "Aufgaben",
      newExercise: "Neue Aufgabe",
      newScheme: "Neues Schema",
      schemelist: "Schemata",
      modellist: "Modelle",
      exerciselist: "Aufgaben",
      search: "Suchen",
      deleteAll: "Alle löschen",
      title: "Titel",
      description: "Beschreibung",
      status: "Status",
      edit: "Bearbeiten",
      chooseTask: "Aufgabe auswählen",
      chooseModel: "Modell auswählen",
      chooseScheme: "Schema auswählen",
      signIn: "Anmelden",
      published: "Veröffentlicht",
      pending: "In Bearbeitung",
      unpublish: "Veröffentlichung zurückziehen",
      delete: "Löschen",
      update: "Aktualisieren",
      updated: "Aktualisiert",
      chooseAssignment: "Bitte eine Aufgabe auswählen",
      submitSuccessfull: "Erfolgreich erstellt!",
      send: "Senden",
      enterTitle: "Titel eingeben",
      artifacts: "Artefakte",
      create: "Erstellen",
      created: "Erstellt",
      add: "Hinzufügen",
      back: "Zurück",
      name: "Kennung",
      type: "Typ",
      modellinglang: "Modellierungssprache",
      modellingTool: "Modellierungseditor",
      language: "Sprache",
      scenario: "Szenario",
      modelCreation: "Modell Erstellen",
      modelUnderstanding: "Modell Verstehen",
      bpmn: "BPMN",
      epc: "EPK",
      petrinet: "Petri Netz",
      umlClass: "UML Klassendiagramm",
      umlSequence: "UML Sequenzdiagramm",
      sampleSolution: "Musterlösung",
      newModel: "Neue Musterlösung erstellen",
      createModelButton: "Erstellen",
      model: "Modell",
      models: "Modelle",
      modelId: "Modell ID",
      chooseFile: "Datei auswählen",
      answer: "Antwort",
      wronganswers: "Falsche Antworten",
      correctanswers: "Korrekte Antworten",
      schemes: "Schemata",
      scheme: "Bewertungsschema",
      visibility: "Sichtbarkeit",
      private: "privat",
      internal: "intern",
      public: "öffentlich",
      question: "Frage",
      signUp: "Registrieren",
      logOut: "Abmelden",
      existing_model: "Bestehendes Modell",
      startAssessment: "Bewertung starten",
      showAssessment: "Bewertungsergebnisse anzeigen",
      selectSubmission: "Einreichung auswählen",
      refresh: "aktualisieren",
      selectModellinglang: "Modellierungssprache auswählen",
      selectScheme: "Bewertungsschema auswählen",
      selectSampleSolution: "Musterlösung auswählen",
      selectVisibility: "Sichtbarkeit auswählen",
      Todo: "Zu bearbeiten",
      submit: "Einreichen",
      MarkasReady: "Als fertig markieren",
      Ready: "fertig",
      submissions: "Einreichungen",
      addNewAssignment: "Neue Aufgabenreihe hinzufügen",
      open: "Offen",
      submitted: "Eingereicht",
      enterAccessCode: "Bitte Zugangscode eingeben",

      acesscodeCardHeader: "Füge eine Aufgabenreihe hinzu",
      acesscodeCardInputLabel: "Hier Zugriffscode eingeben",
      acesscodeCardBody:
        "Hier kannst du eine Aufgabenreihe deinem Account hinzufügen. Nutze dafür den für dich bereitgestellten 6-stelligen Zugriffscode.",

      openSubmissionsCardHeader: "Offene Aufgabenreihen",
      openSubmissionsCardBody:
        "Hier findest du deine aktuell offenen Aufgabenreihen. Jede Aufgabenreihe kann mehrere Aufgaben enthalten. Du kannst die Bearbeitung starten, indem du eine Aufgabenreihe anklickst.",
      openSubmissionsCardTableRowName: "Name",
      openSubmissionsCardTableRowTimeRemaining: "Zeit verbleibend",
      openSubmissionsCardTableRowStatus: "Status",
      openSubmissionsCardTableRowEmpty:
        "Deinem Account sind derzeit keine Aufgabenreihen zugewiesen. Füge doch eine Aufgabenreihe über einen Zugriffscode hinzu!",

      SubmittedSubmissionsCardHeader: "Eingereichte Aufgabenreihen",
      SubmittedSubmissionsCardBody:
        "Hier findest du deine bereits bearbeiteten Aufgabenreihen und kannst deine Lösungen zusammen mit Feedback einsehen.",
      SubmittedSubmissionsCardTableRowName: "Name",
      SubmittedSubmissionsCardTableRowCreated: "Einreichungsdatum",
      SubmittedSubmissionsCardTableRowStatus: "Status",
      openSubmissionsCardTableRowNumberOfExercises: "Aufgaben",
      SubmittedSubmissionsCardTableRowEmpty:
        "Du hast noch keine Aufgabenreihe abgegeben. Bearbeite doch eine Aufgabenreihe und reiche diese ein!",

      RemainingTimeTagLabel: "{{count}} Tag",
      RemainingTimeTagLabel_plural: "{{count}} Tage",
      RemainingTimeTagLabelLessThan: "< {{count}} Tag",
      RemainingTimeTagLabelLessThan_plural: "< {{count}} Tage",
      RemainingTimeTagLabelExpired: "abgelaufen",

      AssignmentTableRowName: "Name",
      AssignmentTableRowLanguage: "Modellierungssprache",
      AssignmentTableRowDescription: "Aufgabenstellung",
      AssignmentEditButton: "Bearbeiten",
      AssignmentSubmitButton: "Einreichen",

      ShowFeedback: "Ergebnisse",
      ShowNoFeedback: "Keine Ergebnisse",
      comment: "Kommentar",
      Score: "Gesamtpunkte",
      category: "Kategorie",
      valuation: "Auswertung",
      content: "Inhalt",
      PositionX: "Position X",
      PositionY: "Position Y",
      AssessmentId: "Bewertungs-Id",
      Assessment: "Bewertung",
      seeFeedback: "Hier kannst du dein Feedback ansehen.",
      integratedFeedback: "Integriertes Feedback",
      viewStatic: "Ansehen",
      ConfirmSubmit:
        "Soll die Aufgabenreihe wirklich abgegeben werden? Dieser Schritt kann nicht mehr rückgängig gemacht werden!",
      exerciseType: "Aufgabentyp",
      percentage: "Anteil",
      password: "Passwort",
      email: "E-Mail Adresse (optional)",
      confirmEmail: "E-Mail Adresse bestätigen",
      confirmPassword: "Passwort bestätigen",
      returnToLogin: "Zurück zum Login",
      logIn: "Anmelden",
      openId: "Mit KIT-Konto einloggen"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "de",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
