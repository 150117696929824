import { ChangeEvent, useCallback } from "react";

import { UserRoleType } from "../../../types/UserRoleType";
import { useTranslation } from "react-i18next";

type Props = {
  type: UserRoleType;
  setUserRoleType: (type: UserRoleType) => void;
};

export const ChooseUserRoleTypeComponent = (props: Props) => {
  const { t } = useTranslation();

  const changeUserRoleType = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.setUserRoleType(event.target.value as UserRoleType);
    },
    [props],
  );

  return (
    <>
      <div className="columns">
        <div className="column has-text-centered is-centered">
          <label className="label has-text-centered is-unselectable">{t("role")}</label>
        </div>
      </div>

      <div className="columns">
        <div className="column has-text-centered">
          <label className="radio" htmlFor={"answer"}>
            <input
              className="radio mr-2 pr-2"
              type="radio"
              name="chosenUI"
              value={UserRoleType.Student}
              checked={props.type === UserRoleType.Student}
              onChange={changeUserRoleType}
            />
            {t("StudentUI")}
          </label>
        </div>
        <div className="column has-text-centered">
          <label className="radio ml-4 pl-4">
            <input
              className="radio mr-2 pr-2"
              type="radio"
              name="chosenUI"
              value={UserRoleType.Author}
              checked={props.type === UserRoleType.Author}
              onChange={changeUserRoleType}
            />
            {t("AuthorUI")}
          </label>
        </div>
      </div>
    </>
  );
};
