import "./i18n/i18n";
import "./index.sass";

import { NotificationContextProvider } from "context/notification/NotificationContextProvider";
import { SessionContextProvider } from "context/session/SessionContextProvider";
import { User } from "oidc-client-ts";
import { AuthenticationPage } from "pages/authentication/AuthenticationPage";
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

declare global {
  interface Window {
    _env_: any;
  }
}

const oidcConfig = {
  authority: window?._env_?.REACT_APP_OIDC_AUTHORITY,
  client_id: window?._env_?.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: window?._env_?.REACT_APP_OIDC_REDIRECT_URI,
  client_secret: window?._env_?.REACT_APP_OIDC_CLIENT_SECRET,
  scope: "openid profile email matriculationNumber",
  loadUserInfo: true,
};

const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <AuthProvider onSigninCallback={onSigninCallback} {...oidcConfig}>
      <SessionContextProvider>
        <NotificationContextProvider>
          <BrowserRouter>
            <AuthenticationPage />
          </BrowserRouter>
        </NotificationContextProvider>
      </SessionContextProvider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
