import { createContext, useContext } from "react";

import { NotificationMessage } from "types/NotificationMessageType";

interface INotificationContext {
  notification: NotificationMessage | undefined;
  setNotification: (notification: NotificationMessage) => void;
}

const defaultState: INotificationContext = {
  notification: undefined,
  setNotification: () => {
    console.warn("NotificationContext: not in scope.");
  },
};

export const NotificationContext = createContext<INotificationContext>(defaultState);
export const useNotificationContext = () => useContext(NotificationContext);
