import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
type Props = {};

export const ImpressumPage = (_props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  function back() {
    navigate(-1);
  }
  return (
    <div>
      <div className="card " style={{ width: "40%", height: "30%" }}>
        <header className="card-header">
          <p className="card-header-title">{t("Impressum")}</p>
        </header>
        <div className="card-content">
          <div className="content">
            <div className="columns">
              <div className="column has-text-weight-light is-italic">impressumsText / HTML</div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <button className="button is-danger" onClick={() => back()}>
          {t("back")}
        </button>
      </div>
    </div>
  );
};
