import { ChangeEvent, useCallback } from "react";

import { ChooseUserRoleTypeComponent } from "./components/ChooseUserRoleTypeComponent";
//import { ConfirmEmailAdressInputFieldComponent } from "./components/ConfirmEmailAdressInputFieldComponent";
import { ConfirmLoginPasswordInputFieldComponent } from "./components/ConfirmLoginPasswordInputFieldComponent";
//import { EmailAdressInputFieldComponent } from "./components/EmailAdressInputFieldComponent";
import { FaExclamationTriangle } from "react-icons/fa";
import { LoginNameInputFieldComponent } from "./components/LoginNameInputFieldComponent";
import { LoginPasswordInputFieldComponent } from "./components/LoginPasswordInputFieldComponent";
import type { UserRoleType } from "../../types/UserRoleType";
import { useTranslation } from "react-i18next";
import { PrivacyPolicyCheckbox } from "./components/PrivacyPolicyCheckbox";

type Props = {
  loginName: string;
  setLoginName: (value: string) => void;
  loginPassword: string;
  setLoginPassword: (value: string) => void;
  secondaryPassword: string;
  emailAdress: string;
  setEmailAdress: (value: string) => void;
  secondaryEmailAdress: string;
  setSecondaryEmailAdress: (value: string) => void;
  setSecondaryPassword: (value: string) => void;
  register: () => void;
  changeRoleTypeEnabled: boolean;
  userRoleType: UserRoleType;
  setUserRoleType: (value: UserRoleType) => void;
  privacyPolicyChecked: boolean;
  setPrivacyPolicyChecked: (value: boolean) => void;
};

export const RegisterView = (props: Props) => {
  const { t } = useTranslation();

  const onChangeLoginName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.setLoginName(e.target.value);
    },
    [props],
  );

  const onChangeLoginPassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.setLoginPassword(e.target.value);
    },
    [props],
  );

  const onChangeSecondaryPassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.setSecondaryPassword(e.target.value);
    },
    [props],
  );

  /*
  const onChangeEmailAdress = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.setEmailAdress(e.target.value);
    },
    [props]
  );
*/
  /*
  const onChangeSecondaryEmailAdress = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.setSecondaryEmailAdress(e.target.value);
    },
    [props]
  );
  */

  const onChangePrivacyPolicyCheckbox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.setPrivacyPolicyChecked(e.target.checked);
    },
    [props],
  );

  return (
    <>
      <div className="columns">
        <div className="column">
          <LoginNameInputFieldComponent loginName={props.loginName} onChangeLoginName={onChangeLoginName} />
          <div className="column has-text-weight-normal has-text-centered is-unselectable">
            <FaExclamationTriangle className="mr-2 has-text-danger" />
            {t("BewareName")}
            <FaExclamationTriangle className="ml-2 has-text-danger" />
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <LoginPasswordInputFieldComponent
            loginPassword={props.loginPassword}
            onChangeLoginPassword={onChangeLoginPassword}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <ConfirmLoginPasswordInputFieldComponent
            secondaryPassword={props.secondaryPassword}
            onChangeSecondaryPassword={onChangeSecondaryPassword}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-weight-normal has-text-centered is-unselectable">
          <FaExclamationTriangle className="mr-2 has-text-danger" />
          {t("BewarePW")}
          <FaExclamationTriangle className="ml-2 has-text-danger" />
        </div>
      </div>

      {/* 
      <div className="columns">
        <div className="column">
          <EmailAdressInputFieldComponent
            emailAdress={props.emailAdress}
            onChangeEmailAdress={onChangeEmailAdress}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <ConfirmEmailAdressInputFieldComponent
            secondaryEmailAdress={props.secondaryEmailAdress}
            onChangeSecondaryEmailAdress={onChangeSecondaryEmailAdress}
          />
        </div>
      </div>
*/}
      {props.changeRoleTypeEnabled && (
        <ChooseUserRoleTypeComponent type={props.userRoleType} setUserRoleType={props.setUserRoleType} />
      )}

      <PrivacyPolicyCheckbox
        privacyPolicyChecked={props.privacyPolicyChecked}
        onChangePrivacyPolicyCheckbox={onChangePrivacyPolicyCheckbox}
      />

      <div className="columns">
        <div className="column">
          <button onClick={props.register} className="button is-primary is-fullwidth">
            {t("signUp")}
          </button>
        </div>
      </div>
    </>
  );
};
